import React from 'react'
import {
  HubspotProvider,
  useHubspotForm,
} from '@aaronhayes/react-use-hubspot-form'
import {
  Container,
  Link,
  Section,
  Seo,
} from '@components'
import { MVLandingPageLayout } from '../../components/site/layout/mv-landing-page-layout'
import { MVMedicalApp } from '../../components/site/customers/images'

const title = 'MV Medic App - Criar Conta / Signup'
const subTitlePT = 'A sua jornada com a Approov e MV inicia-se aqui'
const subTitleEN = 'Start here your journey with Approov and MV'

const MVSignup = ({ location }) => {
  /*const { loaded, error, formCreated } =*/ useHubspotForm({
    portalId: '2449407',
    formId: '25fb0f26-61d6-4b32-86d1-386fd8e58ca8',
    target: '#reg-form',
  })

  return (
    <MVLandingPageLayout pathname={location.pathname}>

      <Section>
        <Container className="mb-8 container-md">
          <article
            className="prose prose-approov max-w-none"
            style={{ maxWidth: '100%' }}
          >
            <h1 className="text-center" style={{ marginBottom: '1rem' }}>
              {title}
            </h1>
            <h2 className="text-center" style={{ marginTop: 0 }}>
              {subTitlePT}
            </h2>
            <h2 className="text-center" style={{ marginTop: 0 }}>
              {subTitleEN}
            </h2>
          </article>
        </Container>
      </Section>

      <Section>
        <Container className="mb-8 container-md">
          <div className="flex flex-col justify-center lg:flex-row lg:content-center lg:items-center">
            <figure className="lg:max-w-2/3">
              <div>{MVMedicalApp}</div>
            </figure>
            <div id="reg-form" className="pt-8 pl-8 pr-2" />
          </div>
        </Container>
      </Section>
      <Section className="container px-2 pt-2 pb-8 mx-auto">
        <Container className="mb-8 container-md">
          <div className="items-center content-center justify-center text-center">
            <p>
              Ao submeter este formulário para criar a sua conta da MV com a
              Approov está a concordar com a nossa{' '}
              <Link href="/privacy">politica de privacidade</Link> e{' '}
              <Link href="/terms">termos de uso</Link>.
            </p>
            <p>
              By submitting this form to create your MV account with Approov you
              are agreeing with our <Link href="/privacy">privacy policy</Link>{' '}
              and <Link href="/terms">terms of use</Link>.
            </p>
          </div>
        </Container>
      </Section>
    </MVLandingPageLayout>
  )
}

const MVSignupPage = ({ location }) => {
  return (
    <HubspotProvider>
      <MVSignup location={location} />
    </HubspotProvider>
  )
}

export default MVSignupPage
